export default {
  language: 'Englinsh',
  title: 'GEOSatDB',
  titleSub: 'Global Civil Earth Observation Satellite Semantic Database',
  dialogTitle: 'Tip',
  dialogLoginAgain: 'Your login information has expired, do you want to log in again?',
  toLoginBtn: 'Go to login',
  toRegisterBtn: 'Go to register',
  cancelBtn: 'Cancel',
  confirmBtn: 'Confirm',
  confirmClose: 'Confirm close？',
  confirmLoginout: 'Confirm logout？',
  homeText: 'Home',
  searchResultText: 'Search Result',
  languageConfig: {
    selectLanguage: 'Select Language',
  },
  moreSearchDialog: {
    title: 'Advanced search',
    keywords: 'Keywords',
    keywordsPlaceholder: 'Please enter keywords',
    operStatus: 'Operational Status Code',
    selectPlaceholder: 'Please select',
    minText: 'Minimum',
    maxText: 'Maximum',
    inputPlaceholder: 'Please enter',
    launchDate: 'Launch Date',
    dataTimeSeparator: '~',
    eolDate: 'End Of Life (EOL) Date',
    owner: 'Owner',
    satelliteAgencies: 'Satellite Agency',
    orbitType: 'Orbit Type',
    repeatCycle: 'Repeat Cycle(day)',
    period: 'Orbital Period（min）',
    ect: 'Equatorial Crossing Time',
    inclination: 'Inclination(°)',
    orbitAltitude: 'Orbit Altitude(km)',
    orbitLongitude: 'Orbit Longitude',
    launchMass: 'Launch Mass (kg)',
    launchSite: 'Launch Site',
  },
  home: {
    searchPlaceholder: 'Please enter content',
    searchBtn: 'Search',
    loginBtn: 'Login',
    registerBtn: 'Register',
    loginoutBtn: 'Logout',
    thanksTitle: 'Acknowledgement',
    thanksContent: 'Thank you for your support',
  },
  login: {
    title: 'User Login',
    email: 'Email',
    emailPlaceholder: 'Please enter email',
    password: 'Password',
    passwordPlaceholder: 'Please enter password',
    unit: 'Unit',
    unitPlaceholder: 'Please select a unit',
    back: 'Back',
  },
  register: {
    title: 'User Register',
    email: 'Email',
    emailPlaceholder: 'Please enter email',
    password: 'Password',
    passwordPlaceholder: 'Please enter password',
    confirmPassword: 'Confirm Password',
    confirmPasswordPlaceholder: 'Please enter password again',
    unit: 'Unit',
    unitPlaceholder: 'Please select a unit',
    back: 'Back',
  },
  searchResult: {
    searchPlaceholder: 'Please enter satellite name/alias',
    searchBtn: 'Search',
    moreBtn: 'More',
    detailBtn: 'Detail',
    alias: 'Alias',
    operStatus: 'Operational Status Code',
    orbitType: 'Orbit Type',
    launchDate: 'Launch Date',
    satelliteAgencies: 'Satellite Agency',
    noData: 'No data',
    resetBtn: 'Reset',
    tipsStart: '',
    tipsEnd: ' satellites are found',
    sortType: 'Sort By',
    advancedSearch: 'Advanced Search',
    simpleSearch: 'Simple Search',
    hotSearch: 'Popular Filters',
  },
  searchInstrument: {
    searchResultText: 'Instrument Information',
    waveBandRegion: 'Waveband Region',
    searchPlaceholder: 'Please enter instrument name',
    type: 'Instrument Type',
    status: 'Instrument Status',
    geometry: 'View Geometry',
    sampling: 'Sampling',
    horizontalResolution: 'Horizontal Resolution (m)',
    verticalResolution: 'Vertical Resolution (km)',
    spectralResolution: 'Spectral Resolution',
    swathWidth: 'Swath Width(km)',
    bestRevisitTime: 'Best Revisit Time (day)',
    boundUnit: 'Wavelength/Frequency-Unit',
    bound: 'Wavelength/Frequency',
    tipsStart: '',
    tipsEnd: ' instruments are found'
  },
  satelliteDetail: {
    title: 'Satellite Information',
    feedback: 'Feedback',
    alias: 'Alternate Names',
    cosparId: 'COSPAR ID',
    noradId: 'NORAD ID',
    launchDate: 'Launch Date',
    endOfLifeDate: 'End Of Life Date',
    owner: 'Owner',
    satelliteAgency: 'Satellite Agency',
    agency: 'Agency',
    launchSite: 'Launch Site',
    operStatus: 'Operational Status Code',
    earthObservationInstruments: 'Earth Observation Instruments',
    orbitType: 'Orbit Type',
    orbitalPeriod: 'Orbital Period (min)',
    orbitAltitude: 'Orbit Altitude (km)',
    apogeeAltitude: 'Apogee Altitude (km)',
    perigeeAltitude: 'Perigee Altitude (km)',
    inclination: 'Inclination (°)',
    orbitLongitude: 'Orbit Longitude (km)',
    equatorialCrossingTime: 'Equatorial Crossing Time',
    repeatCycle: 'Repeat Cycle (day)',
    launchMass: 'Launch Mass (kg)',
    dryMass: 'Dry Mass (kg)',
    applications: 'applications',
    eoPortal: 'eoPortal',
    websitesInformation: 'Website Information',
    dataAccessPortal: 'Data Access Portal',
    dialogTitle: 'Feedback',
    inputPlaceholder: 'Please enter',
    inputLabel: 'Feedback Content',
    sureBtn: 'Confirm',
    cancelBtn: 'Cancel',
    dataErrMsg: 'Data format error',
    sureMsg: 'Feedback success！',
    instrumentStatus: 'Instrument Status',
  },
  instrumentDetail: {
    title: 'Instrument Information',
    feedback: 'Feedback',
    alias: 'Alternate Names',
    wavebandCategories: 'Waveband Categories',
    instrumentAgency: 'Instrument Agency',
    instrumentStatus: 'Instrument Status',
    instrumentType: 'Instrument Type',
    viewGeometry: 'View Geometry',
    sampling: 'sampling',
    bestSpatialResolution: 'Best Spatial Resolution (m)',
    bestRevisitTime: 'Best Revisit Time (day)',
    maximumSwath: 'Maximum Swath (km)',
    description: 'Description',
    onboardSatellites: 'Onboard Satellites',
    waveband: 'Waveband',
    wavebandCategory: 'Waveband Category',
    wavelength: 'Wavelength (µm)',
    operationMode: 'Operation Mode',
    horizontalResolution: 'Horizontal Resolution (m)',
    swathWidth: 'Swath Width (km)',
    dialogTitle: 'Feedback',
    inputPlaceholder: 'Please enter',
    inputLabel: 'Feedback Content',
    sureBtn: 'Confirm',
    cancelBtn: 'Cancel',
    dataErrMsg: 'Data format error',
    sureMsg: 'Feedback success！',
    spectralResolution: 'Spectral Resolution',
    incidenceAngle: 'Incidence Angle (°)',
    polarization: 'Polarization',
    radiometricResolution: 'Radiometric Resolution',
    revisitTime: 'Revisit Time (day)',
    verticalResolution: 'Vertical Resolution (km)',
  },
  examine: {
    title: 'Examine Information',
    name: 'Name',
    satelliteTitle: 'Satellite Information Examine',
    instrumentTitle: 'Instrument Information Examine',
    detailBtn: 'Detail',
    creator: 'Creator',
    confirmBtn: 'Confirm',
    state: 'State',
    waiting: 'Waiting',
    passed: 'Passed',
    sended: 'Sended',
    closeBtn: 'Close',
    dialogTitle: 'Prompt',
    dialogContent: 'Confirm to process this information?',
    cancemBtn: 'Cancel',
    backMsg: 'Process success',
    updateBtn: 'Upload File',
    updateDialogTitle: 'Upload File',
    uploadLabel: 'Select File',
    uploadMsg: 'Please select file',
    uploadSuccessMsg: 'Upload Success',
    confirmUpdateBtn: 'Confirm Upload',
    adminUpdateTime: 'Admin Update Time',
    sendEmailTime: 'Send Email Time',
    createTime: 'Feedback Time',
  },
  router: {
    filterResultTitle: 'Satellite Information',
    filterInstrumentTitle: 'Instrument Information',
    examineList: 'Examine List',
    serviceInformation: 'Server Information',
    accessStatistics: 'Access Statistics',
    aboutPage: 'About',
    organizationalManagement: 'Organizational Management',
  },
  serviceInformation: {
    title: 'Server Information',
    sys: 'System',
    sysFile: 'System File',
    cpu: 'CPU',
    memory: 'Memory',
    jvm: 'JVM',
    computerName: 'Computer Name',
    osArch: 'OS Architecture',
    osName: 'OS Name',
    totalSysFile: 'Total System File',
    usedSysFile: 'Used System File',
    freeSysFile: 'Free System File',
    sysTypeName: 'System Type Name',
    cpuNum: 'CPU Number',
    totalCup: 'Total',
    usedCpu: 'Used',
    freeCpu: 'Free',
    totalMemary: 'Total',
    usedMemary: 'Used',
    freeMemary: 'Free',
    jvmName: 'Name',
    jvmVersion: 'Version',
    jvmStartTime: 'Start Time',
    jvmRunTime: 'Run Time',
    jvmTotal: 'Total',
    jvmFree: 'Free',
    jvmMax: 'Max',
  },
  accessStatistics: {
    title: 'Access Statistics',
    noData: 'No Data',
    visitNum: 'Visit Count',
    accumulateVisit: 'Accumulate Visit Count',
    visitText: 'Visit',
    total: 'Total',
    pageVisit: 'Page Visit',
    flieDownload: 'File Download',
    referenceFileDownload: 'Reference File Download',
    visitsCount: 'Visits Count',
    satelliteCount: 'Satellite Page Visit',
    instrumentCount: 'Instrument Page Visit',
    allVisitsCount: 'All Visits Count',
    allSatelliteCount: 'All Satellite Page Visit',
    allInstrumentCount: 'All Instrument Page Visit',
    downloadVisit: 'Export Access Statistics',
    downloadGlobalVisit: 'Export Global Access Statistics',
  },
  aboutPage: {
    title: 'About'
  },
  organizational: {
    organizationalManage: 'Organizational Management',
    organizationalName: 'Organizational Name',
    inputPlaceholder: 'Please enter',
    searchBtn: 'Search',
    cleanBtn: 'Clean',
    mergeOrganizations: 'Merge Organizations',
    reserved: 'Reserved',
    selectPlaceholder: 'Please select',
    cancelBtn: 'Cancel',
    confirmBtn: 'Confirm',
    confirmMerge: 'Confirm Merge',
    failMerge: 'Merge Failed',
    mergeSuccess: 'Merge Success',
  },
  satelliteFeedback: {
    alternateNames: 'Alternate Names',
    apogee: 'Apogee Altitude',
    applications: 'description',
    cosparId: 'International Designator',
    dataPortal: 'Data Access Portal',
    dryMass: 'Dry Mass (kg.)',
    ect: 'Equatorial Crossing Time',
    eoPortal: 'EO Portal',
    eolDate: 'end of life (EOL) Date',
    hasInstrumentId: 'Has Instrument',
    inclination: 'Inclination',
    instruments: 'Instrument',
    instrumentIds: 'CEOS Instrument IDs',
    instrumentNames: 'Instrument Names',
    isEo: 'EO/Non-EO',
    launchDate: 'Launch Date',
    launchMass: 'Launch Mass (kg.)',
    launchSite: 'Launch Site',
    noradId: 'Satellite Catalog Number',
    objectType: 'Object Type',
    operStatusCode: 'Operational Status Code',
    orbitAltitude: 'Orbit Altitude',
    orbitCenter: 'Orbit Center',
    orbitLongitude: 'Orbit Longitude',
    orbitSense: 'Orbit Sense',
    orbitType: 'Orbit Type',
    owner: 'Owner',
    perigee: 'Perigee Altitude',
    period: 'orbital period',
    rcs: 'Radar Cross Section',
    relatedSatIds: 'Related Satellite Ids',
    repeatCycle: 'Repeat Cycle',
    satelliteAgencies: 'satellite agency',
    satelliteName: 'Satellite Name',
    webInfo: 'websites Information',
    rawData: 'Raw Data',
    feedbackData: 'Feedback Data'
  },
  instrumentFeedback: {
    name: 'Name',
    type: 'Type',
    alternateName: 'Alternate Name',
    waveBandRegion: 'Wave Band Region',
    agency: 'Agency',
    status: 'Status',
    geometry: 'Geometry',
    sampling: 'Sampling',
    resolutionBest: 'Resolution Best',
    revisitTimeBest: 'Revisit Time Best',
    maxSwath: 'Max Swath',
    description: 'Description',
    rawData: '原始数据',
    feedbackData: '反馈数据',
    operationalBand: 'Operational Band',
    boundMax: 'Bound Max',
    boundMin: 'Bound Min',
    boundUnit: 'Bound Unit',
    spectralResolution: 'Spectral Resolution',
    numberOfBands: 'Number Of Bands',
    operation: 'Operation',
    region: 'Region',
    horizontalResolution: 'Horizontal Resolution (m)',
    verticalResolution: 'Vertical Resolution (km)',
    incidenceAngle: 'Incidence Angle (°)',
    operationalMode: 'Operational Mode',
    polarization: 'Polarization',
    radiometricResolution: 'Radiometric Resolution',
    revisitTime: 'Revisit Time (day)',
    swathWidth: 'Swath Width (km)'
  }
};
