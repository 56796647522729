export default {
  language: '中文',
  title: 'GEOSatDB',
  titleSub: '全球民用地球观测卫星语义数据库',
  dialogTitle: '提示',
  dialogLoginAgain: '您的登录信息已过期，是否重新登录？',
  toLoginBtn: '去登录',
  toRegisterBtn: '去注册',
  cancelBtn: '取消',
  confirmBtn: '确定',
  confirmClose: '确定关闭？',
  confirmLoginout: '确定退出登录？',
  homeText: '首页',
  searchResultText: '筛选结果',
  languageConfig: {
    selectLanguage: '选择语言',
  },
  moreSearchDialog: {
    title: '高级搜索',
    keywords: '关键字',
    keywordsPlaceholder: '请输入关键字',
    operStatus: '运行状态',
    selectPlaceholder: '请选择',
    minText: '最小',
    maxText: '最大',
    inputPlaceholder: '请输入',
    launchDate: '发射日期',
    dataTimeSeparator: '~',
    eolDate: '退役日期',
    owner: '所有者',
    satelliteAgencies: '负责机构',
    orbitType: '轨道类型',
    repeatCycle: '重复周期 / 轨道重访周期(天)',
    period: '轨道周期（分钟）',
    ect: '赤道穿越时间',
    inclination: '轨道倾角(°)',
    orbitAltitude: '轨道高度(千米)',
    orbitLongitude: '轨道经度(地球同步轨道)',
    launchMass: '总质量(公斤)',
    launchSite: '发射地点',
  },
  home: {
    searchPlaceholder: '请输入内容',
    searchBtn: '搜索',
    loginBtn: '登录',
    registerBtn: '注册',
    loginoutBtn: '退出登录',
    thanksTitle: '鸣谢',
    thanksContent: '感谢支持',
  },
  login: {
    title: '用户登录',
    email: '邮箱',
    emailPlaceholder: '请输入邮箱',
    password: '密码',
    passwordPlaceholder: '请输入密码',
    unit: '单位',
    unitPlaceholder: '请选择单位',
    back: '返回',
  },
  register: {
    title: '用户注册',
    email: '邮箱',
    emailPlaceholder: '请输入邮箱',
    password: '密码',
    passwordPlaceholder: '请输入密码',
    confirmPassword: '确认密码',
    confirmPasswordPlaceholder: '请再次输入密码',
    unit: '单位',
    unitPlaceholder: '请选择单位',
    back: '返回',
  },
  searchResult: {
    searchPlaceholder: '请输入卫星名称/别名',
    searchBtn: '搜索',
    moreBtn: '查看更多',
    detailBtn: '查看详情',
    alias: '别名',
    operStatus: '运行状态',
    orbitType: '轨道类型',
    launchDate: '发射日期',
    satelliteAgencies: '负责机构',
    noData: '暂无数据',
    resetBtn: '重置',
    tipsStart: '查询到',
    tipsEnd: '颗卫星资源',
    sortType: '排序方式',
    advancedSearch: '高级搜索',
    simpleSearch: '简单搜索',
    hotSearch: '热门搜索',
  },
  searchInstrument: {
    searchResultText: '载荷信息',
    waveBandRegion: '波段范围',
    searchPlaceholder: '请输入载荷名称',
    type: '载荷类型',
    status: '载荷状态',
    geometry: '成像方式',
    sampling: '采样方式',
    horizontalResolution: '水平分辨率(m)',
    verticalResolution: '垂直分辨率(km)',
    spectralResolution: '光谱分辨率',
    swathWidth: '幅宽(千米)',
    bestRevisitTime: '最佳重访周期(天)',
    boundUnit: '波长/频率-单位',
    bound: '波长/频率',
    tipsStart: '查询到',
    tipsEnd: '条载荷信息',
  },
  satelliteDetail: {
    title: '卫星信息',
    feedback: '反馈',
    alias: '别称',
    cosparId: '国际卫星标识符',
    noradId: '卫星目录序号',
    launchDate: '发射日期',
    endOfLifeDate: '终止日期',
    owner: '所有者',
    satelliteAgency: '所属机构',
    agency: '卫星机构',
    launchSite: '发射地点',
    operStatus: '运行状态',
    earthObservationInstruments: '地球观测载荷',
    orbitType: '轨道类型',
    orbitalPeriod: '轨道周期 / 运行周期(分钟)',
    orbitAltitude: '轨道高度(千米)',
    apogeeAltitude: '远地点高度(千米)',
    perigeeAltitude: '近地点高度(千米)',
    inclination: '倾角(°)',
    orbitLongitude: '轨道经度',
    equatorialCrossingTime: '赤道过境时间',
    repeatCycle: '重复周期 / 轨道重访周期 (天)',
    launchMass: '发射质量（公斤）',
    dryMass: '干质量（公斤）',
    applications: '应用',
    eoPortal: 'eoPortal',
    websitesInformation: '相关网站信息',
    dataAccessPortal: '数据访问',
    dialogTitle: '信息反馈',
    inputPlaceholder: '请输入',
    inputLabel: '反馈内容',
    sureBtn: '确定',
    cancelBtn: '取消',
    dataErrMsg: '数据格式错误',
    sureMsg: '反馈成功！',
    instrumentStatus: '载荷状态',
  },
  instrumentDetail: {
    title: '载荷信息',
    feedback: '反馈',
    alias: '别称',
    wavebandCategories: '波段类型',
    instrumentAgency: '管理机构',
    instrumentStatus: '载荷状态',
    instrumentType: '载荷类型',
    viewGeometry: '成像方式',
    sampling: '采样方式',
    bestSpatialResolution: '最佳空间分辨率(m)',
    bestRevisitTime: '最佳重访周期(天)',
    maximumSwath: '最大幅宽(千米)',
    description: '简介',
    onboardSatellites: '搭载卫星',
    waveband: '波段',
    wavebandCategory: '波段类型',
    wavelength: '波长(µm)',
    operationMode: '运行模式',
    horizontalResolution: '水平分辨率(m)',
    swathWidth: '幅宽(千米)',
    dialogTitle: '信息反馈',
    inputPlaceholder: '请输入',
    inputLabel: '反馈内容',
    sureBtn: '确定',
    cancelBtn: '取消',
    dataErrMsg: '数据格式错误',
    sureMsg: '反馈成功！',
    spectralResolution: '光谱分辨率',
    incidenceAngle: '入射角(°)',
    polarization: '偏振',
    radiometricResolution: '辐射分辨率',
    revisitTime: '重访周期(天)',
    verticalResolution: '垂直分辨率(km)',
  },
  examine: {
    title: '审核信息',
    name: '名称',
    satelliteTitle: '卫星信息审核',
    imstrumentTitle: '载荷信息审核',
    detailBtn: '查看详情',
    creator: '创建者',
    confirmBtn: '确认处理',
    state: '状态',
    waiting: '待审核',
    passed: '已审核',
    sended: '已发送',
    closeBtn: '关闭',
    dialogTitle: '提示',
    dialogContent: '确认处理该信息？',
    cancemBtn: '取消',
    backMsg: '处理成功',
    updateBtn: '上传文件',
    updateDialogTitle: '上传文件',
    uploadLabel: '选择文件',
    uploadMsg: '请选择文件',
    uploadSuccessMsg: '上传成功',
    confirmUpdateBtn: '确认上传',
    adminUpdateTime: '管理员修改时间',
    sendEmailTime: '发送邮件时间',
    createTime: '反馈时间',
  },
  router: {
    filterResultTitle: '卫星信息',
    filterInstrumentTitle: '载荷信息',
    examineList: '审核列表',
    serviceInformation: '服务器信息',
    accessStatistics: '访问统计',
    aboutPage: '简介',
    organizationalManagement: '组织管理',
  },
  serviceInformation: {
    title: '服务器信息',
    sys: '系统',
    sysFile: '系统文件',
    cpu: 'CPU',
    memory: '内存',
    jvm: 'JVM',
    computerName: '计算机名',
    osArch: '操作系统架构',
    osName: '操作系统名称',
    totalSysFile: '系统文件空间',
    usedSysFile: '系统文件已用空间',
    freeSysFile: '系统文件可用空间',
    sysTypeName: '系统类型名称',
    cpuNum: 'CPU数量',
    totalCup: '总CPU',
    usedCpu: '已用CPU',
    freeCpu: '可用CPU',
    totalMemary: '总内存',
    usedMemary: '已用内存',
    freeMemary: '可用内存',
    jvmName: '名称',
    jvmVersion: '版本',
    jvmStartTime: '启动时间',
    jvmRunTime: '运行时间',
    jvmTotal: '总内存',
    jvmFree: '可用内存',
    jvmMax: '最大内存',
  },
  accessStatistics: {
    title: '访问统计',
    noData: '暂无数据',
    visitNum: '访问量统计',
    accumulateVisit: '累计访问量统计',
    visitText: '访问量',
    total: '总量',
    pageVisit: '数据集页面访问次数',
    flieDownload: '数据集文件下载次数',
    referenceFileDownload: '引用文件下载次数',
    visitsCount: '网站访问次数',
    satelliteCount: '卫星页面访问次数',
    instrumentCount: '载荷页面访问次数',
    allVisitsCount: '网站累计访问次数',
    allSatelliteCount: '卫星累计访问次数',
    allInstrumentCount: '载荷累计访问次数',
    downloadVisit: '导出访问统计',
    downloadGlobalVisit: '导出全球访问统计',
  },
  aboutPage: {
    title: '简介'
  },
  organizational: {
    organizationalManage: '组织管理',
    organizationalName: '组织名称',
    inputPlaceholder: '请输入',
    searchBtn: '搜索',
    cleanBtn: '清空',
    mergeOrganizations: '合并组织',
    reserved: '保留',
    selectPlaceholder: '请选择',
    cancelBtn: '取消',
    confirmBtn: '确定',
    confirmMerge: '确定合并',
    failMerge: '合并失败',
    mergeSuccess: '合并成功',
  },
  satelliteFeedback: {
    alternateNames: '别称',
    apogee: '远地点高度(千米)',
    applications: '实际应用简述',
    cosparId: '国际卫星标识符',
    dataPortal: '数据门户资源链接',
    dryMass: '干质量',
    ect: '赤道穿越时间',
    eoPortal: 'EO数据门户资源链接',
    eolDate: '退役日期',
    hasInstrumentId: '包含载荷',
    inclination: '轨道倾角',
    instruments: '载荷',
    instrumentIds: '载荷标识符',
    instrumentNames: '载荷名称',
    isEo: '地球观测标识符',
    launchDate: '发射日期',
    launchMass: '总质量',
    launchSite: '发射地点',
    noradId: '卫星目录序号',
    objectType: '对象类型',
    operStatusCode: '运行状态',
    orbitAltitude: '轨道高度',
    orbitCenter: '轨道中心',
    orbitLongitude: '轨道经度(地球同步轨道)',
    orbitSense: '轨道方位',
    orbitType: '轨道类型',
    owner: '所有者',
    perigee: '近地点高度',
    period: '轨道周期',
    rcs: '雷达散射截面',
    relatedSatIds: '关联的标识符',
    repeatCycle: '重复周期 / 轨道重访周期',
    satelliteAgencies: '负责机构',
    satelliteName: '卫星名称',
    webInfo: '相关网页资源链接',
    rawData: '原始数据',
    feedbackData: '反馈数据'
  },
  instrumentFeedback: {
    name: '名称',
    type: '类型',
    alternateName: '别称',
    waveBandRegion: '波段范围',
    agency: '负责机构',
    status: '状态',
    geometry: '成像方式',
    sampling: '采样方式',
    resolutionBest: '最佳空间分辨率(m)',
    revisitTimeBest: '最佳重访周期(天)',
    maxSwath: '最大幅宽(千米)',
    description: '简介',
    rawData: '原始数据',
    feedbackData: '反馈数据',
    operationalBand: '工作波段',
    boundMax: '波长/频率-最大值',
    boundMin: '波长/频率-最小值',
    boundUnit: '波长/频率-单位',
    spectralResolution: '光谱分辨率',
    numberOfBands: '波段数量',
    operation: '操作',
    region: '区域',
    horizontalResolution: '水平分辨率(m)',
    verticalResolution: '垂直分辨率(km)',
    incidenceAngle: '入射角(°)',
    operationalMode: '工作模式',
    polarization: '偏振',
    radiometricResolution: '辐射分辨率',
    revisitTime: '重访周期(天)',
    swathWidth: '幅宽(千米)'
  }
};
